<template>
	<section class="domain_content">
		<!-- privacy policy -->
		<div class="sub-page">
			<h1>Privacy Policy</h1>
			<p>
				This Privacy Policy for <a href="https://waplusapk.download/">https://waplusapk.download/</a> explains how and why we may access or collect your personal information when you use our services, including visiting our website or any linked site that refers to this Privacy Policy.
			</p>
			<p>
				We take full responsibility for how your personal data is handled. If you do not agree with our privacy practices, we advise against using our services.
			</p>
			<div class="part">
				<h2>
					What Personal Information Do We Collect?
				</h2>
				<p>
					When you visit or use our services, the personal information we collect depends on your interactions with us, the services you use, and the choices you make. This may include data such as your contact details, browsing behavior, or device information.
				</p>
				<h2>
					Do We Process Sensitive Information?
				</h2>
				<p>
					In certain jurisdictions, some information may be classified as "sensitive," such as your racial or ethnic origin, sexual orientation, or religious beliefs. We do not process any sensitive personal information.
				</p>
				<h2>
					Do We Collect Information from Third Parties?
				</h2>
				<p>
					We do not collect any personal data from third-party sources. All the information we process comes directly from your interactions with our services.
				</p>
				<h2>
					How Do We Use Your Information?
				</h2>
				<p>
					We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We process your information only when we have a valid legal reason to do so.
				</p>
				<h2>
					How do we keep your information safe? 
				</h2>
				<p>
					We use your information to provide, maintain, and improve our services, communicate with you, enhance security, and prevent fraud. We only process your data when we have a legitimate legal reason to do so, ensuring it is used appropriately.
				</p>
				<h2>
					How Do We Protect Your Information?
				</h2>
				<p>
					We use technical measures to protect your personal data. However, no online system or storage method can be completely secure, and we cannot guarantee absolute protection from unauthorized access or cyberattacks. If you have any concerns or need assistance, feel free to contact us.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'Privacy Policy of this site introduces how we use your information when you visit our site and use the service.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://waplusapk.download/privacy/"
            }]
	},
}
</script>
  